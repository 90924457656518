<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Novo Expurgo"
      subtitle="Selecione o mês e entidades que serão apagadas"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <expurgo-bdgd-wizard-Tabs
        ref="expurgoBdgdParametros"
        :items="expurgos"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import ExpurgosService from '@/services/ExpurgosService';
import wizardMixins from '@/mixins/wizardMixins';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins, tourMixins],

  components: {
    ExpurgoBdgdWizardTabs: () =>
      import('@/components/dados/expurgo-bdgd/ExpurgoBdgdWizardTabs'),
    MaterialWizard: () => import('@/components/base/MaterialWizard')
  },

  data: () => ({
    tabs: ['Mês dos Dados', 'Entidades', 'Confirmação'],
    loading: false,
    expurgos: {},
    arrTourPrimeiraTab: [
      {
        element: '#first-step-expurgo-table',
        intro: 'Aqui você deverá selecionar o mês que será apagado.',
        scrollTo: 'tooltip',
        position: 'top'
      }
    ],
    arrTourSegundaTab: [
      {
        element: '#second-step-expurgo-table',
        intro: 'Aqui você deverá selecionar as entidades que serão apagadas.',
        scrollTo: 'tooltip',
        position: 'top'
      },
      {
        element: '#second-step-search',
        intro: 'Neste campo você poderá pesquisar por entidades específicas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    arrTourTerceiraTab: [
      {
        element: '#third-step-expurgo-confirm',
        intro:
          'Nessa seção será possivel visualizar o total de linhas a serem excluídas.',
        scrollTo: 'tooltip',
        position: 'top'
      }
    ]
  }),

  mounted() {
    this.iniciarTourPrimeiraTab();
  },

  methods: {
    save() {
      const data = this.$refs.expurgoBdgdParametros.exportData();
      this.loading = true;

      ExpurgosService.save(data)
        .then(() => {
          this.$toast.success('Expurgo de BDGD salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['EXPURGOS_DE_BDGD_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar Expurgo de BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPrimeiraTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.tab = 1;
          this.iniciarTourSegundaTab();
        }
      };

      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },

    iniciarTourSegundaTab() {
      let onBeforeExitFunction = {
        func: () => {
          this.tab = 2;
          this.iniciarTourTerceiraTab();
        }
      };

      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    },

    iniciarTourTerceiraTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: () => (this.tab = 0)
      };

      this.iniciarTour(this.arrTourTerceiraTab, [], [], [onBeforeExitFunction]);
    }
  }
};
</script>
